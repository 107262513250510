// Superfish Main Menu
.superfish-navigation {
  background-color: $tekra-blue;

  // Mobile menu toggle
  .sf-accordion-toggle {
    #superfish-1-toggle {
      align-items: stretch;
      background: $tekra-blue;
      display: flex;
      justify-content: flex-end;

      span {
        color: $white;
        display: block;
        font: bold 14px/45px $font-family--heading;

        &:after {
          content: '\f0c9';
          font-family: $font-awesome;
          margin: 0 1em;
        }
      }
    }
  }

  // Top-level <ul>
  .sf-main-menu {
    @include constrain-content;
    align-items: stretch;
    background: $tekra-blue;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 0 !important;
    position: relative;

    // All anchor tags in menu
    a {
      border-right: 1px solid #6792B9;
      color: $white;
      font: bold 14px $font-family--heading;
      padding: 0 15px;

      // ...that have submenus
      &.menuparent {
        padding-right: 32px;

        &:after {
          content: '';
          display: block;
          height: 0;
          border: solid 5px;
          border-color: transparent transparent transparent white;
          margin-right: 15px;
          margin-top: -2.5px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
        }
      }
    }

    // Top-level <li> tags under main menu
    .menu-item.sf-depth-1 {
      flex: 1 0 auto;
      float: none;

      &:hover,
      &.sfHover {
        > ul {
          top: 45px;
        }
      }

      // Top level links
      a.sf-depth-1 {
        background: $tekra-blue;
        border-right: 1px solid #6792B9;
        font: bold 14px/45px $font-family--heading;
        text-align: center;

        &.menuparent {
          // Add down arrow
          &:after {
            border-color: white transparent transparent transparent;
            top: 60%;
          }
        }
      }

      &.active,
      &.sfHover,
      &:hover {
        a.sf-depth-1 {
          background: $tekra-blue-hover;
        }
      }

      &:last-child a {
        border: none;
      }
    }

    // All submenus in menu
    ul {
      background-color: #053f70;
      padding: 0 !important;
    }

    // All second-level and higher menu item <li> tags
    .menu-item:not(.sf-depth-1) {
      padding: 0 5px;

      &:hover {
        background-color: #3596D9;

        &:not(.first) {
          border-top: 1px solid #3596d9;
          margin-top: -1px;
        }
      }

      &:not(.last) > a {
        border-bottom: 1px solid #3596D9;
      }

      a {
        border: none;
        line-height: 30px;
        margin: 0 10px;
        padding: 0 5px;

        &:after {
          margin-right: 0;
        }
      }
    }

    // Set fixed top for submenus
    > .menu-item > ul > .menu-item {
      position: static;

      > ul {
        top: 0;

        > .menu-item {
          position: static;

          > ul {
            top: 0;
          }
        }
      }
    }
  }
}
