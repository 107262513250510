.view-mode-teaser {
  align-items: center;
  background-color: $tekra-gray;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding: 1.7rem 1.3rem;

  @media screen and (min-width: 768px) {
    align-items: stretch;
    flex-flow: row nowrap;
  }

  .group-image {
    flex: 0 0 auto;
    margin-right: 1.4rem;
  }

  .group-content {
    flex: 1 1 auto;
  }

  .teaser-title {
    font: 23px/26px $font-family--heading;
    margin-bottom: 1.5rem;

    a {
      color: $tekra-blue;
    }
  }

  .field-name-field-summary {
    font: 15px/22px $font-family--body;
    margin-bottom: 1.7rem;
  }
}
