.view-show-menu-children {
  @include constrain-content;
  margin-bottom: 6rem;

  .view-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @media screen and (min-width: 920px) {
      margin-right: -1rem;
    }
  }

  .views-row {
    clear: both;
    flex: 0 0 100%;
    overflow: hidden;
    padding: 0 1rem 1rem 0;
    position: relative;

    @media screen and (min-width: 600px) {
      flex: 0 0 50%;
    }
  }

  .field-name-node-link p {
    margin: 0;
  }
}

.view-display-id-block_search_results {
  .views-row {
    margin-bottom: 1rem;
  }

  .pager {
    align-items: center;
    border-top: 3px solid $black;
    display: flex;
    flex-flow: row nowrap;
    font-weight: bold;
    justify-content: center;
    line-height: 23px;
    margin-top: 2rem;
    padding: 2rem 0 2rem !important;

    a {
      color: $black;
    }

    .pager-first,
    .pager-previous {
      &:before {
        content: '\f100';
        font-family: $font-awesome;
        margin-right: 0.75rem;
      }
    }

    .pager-previous:before {
      content: '\f104';
    }

    .pager-next,
    .pager-last {
      &:after {
        content: '\f101';
        font-family: $font-awesome;
        margin-left: 0.75rem;
      }
    }

    .pager-next:after {
      content: '\f105';
    }

    .pager-item,
    .pager-current {
      background-color: $tekra-gray;
      text-align: center;
      width: 21px;
    }

    .pager-current {
      background-color: $tekra-blue-light;
      color: $white;
    }
  }
}
