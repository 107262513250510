.product-spotlight {
  @include constrain-content;
  background-color: $tekra-gray;
  border-top: 10px solid $black;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 6rem;
  padding: 2rem 3rem;

  .left {
    flex: 1 1 400px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
  }

  .right {
    flex: 1 1 300px;
  }

  .headline {
    font: 23px/26px $font-family--heading;
  }

  .resource-title {
    font: bold 15px/22px $font-family--heading;

    a {
      color: $text-color;
    }
  }
}
