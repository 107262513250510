.content-flex {
  display: flex;
  flex-flow: row wrap;

  @media screen and (min-width: 800px) {
    flex-flow: row nowrap;
  }
}

.interior-content {
  flex: 1 1 auto;
  padding: 0 1rem 2rem;
  width: 100%;

  h1 {
    color: $tekra-blue;
    font: bold 28px/32px $font-family--heading;
    margin: 0.25rem 0 1rem;
  }

  h2 {
    font: normal 23px/26px $font-family--heading;
    margin-bottom: 2rem;
  }

  h4 {
    font: bold 15px/18px $font-family--heading;
    margin: 0;
  }
}

#content {
  .copy-wrapper ul {
    margin-left: 15px;
  }

  li#group_overview_tabTab.active div.content h2 {
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 700;
  }

  .media-youtube-video.media-element iframe {
    @include responsive-video;
  }

  .video-embed {
    @include responsive-video;
  }
}
