.search-input-form {
  position: relative;

  .views-exposed-widgets,
  .views-exposed-widget {
    margin: 0;
    padding: 0;
  }

  &:before {
    color: $tekra-blue;
    content: '\f002';
    display: block;
    font-family: $font-awesome;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    font-size: 2rem;
    line-height: 3.2rem;
    width: 3.2rem;
  }

  .form-text {
    margin: 0;
    padding: 1px 18px 1px 2px;
  }

  .form-submit {
    background: none;
    bottom: 0;
    box-shadow: none;
    color: transparent;
    display: block;
    margin: 0 !important;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .utility-search-block &,
  .utility-area & {
    /*
    &:before {
      font-size: 12px;
      line-height: 18px;
      width: 18px;
    }

     */

    .views-exposed-widgets,
    .views-exposed-widget {
      float: none;
    }

    .form-text {
      //height: 18px;
      //width: 125px;
      border: 1px solid $tekra-blue;
      padding-top: 2px;
      padding-bottom: 2px;
      height: 36px;
    }

    .form-submit {
      //height: 18px;
      //width: 18px;
    }
  }

  .interior-content & {
    position: relative;
    display: inline-block;

    &:before {
      font-size: 2rem;
      line-height: 3.2rem;
      width: 3.2rem;
    }

    .form-submit {
      height: 3.2rem;
      width: 3.2rem;
    }
  }
}
