.have-a-question-block {
  background-color: $tekra-gray;
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: center;

  h2 {
    font: bold 15px/19px $font-family--body;
    margin-bottom: 1.5rem;
  }

  p {
    font: 12px/16px $font-family--body;
    margin-bottom: 1.4rem;
  }

  .button {
    font: bold 12px $font-family--heading;
  }
}

.footer-contact-info,
#block-bean-newsletter-signup-block,
.footer-newsletter-signup,
.footer-social-links {
  flex: 1 0 300px;
  padding: 0 1rem;
}

.footer-newsletter-signup {
  display: flex;
  justify-content: center;
  align-items: center;
 
  p {
    @media screen and (min-width: 910px) {  
      margin-bottom: 0;
    }
  }
 
  .button {
    height: 52px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}

#block-bean-newsletter-signup-block {
  h2 {
    font: bold 12px $font-family--body;
    margin: 0 0 1rem;
  }

  label {
    @include visually-hidden;
  }

  .form-actions {
    text-align: right;

    input {
      display: inline-block;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      width: auto;
    }
  }
}

.footer-social-links {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  ul {
    background-color: #88898A;
    margin: 0;
    padding: 1rem !important;

    li {
      display: inline-block;
      margin: 0;
      padding: 0 1rem;

      a {
        color: $white;
        font-size: 1.8rem;

        &:hover {
          color: $tekra-blue;
        }
      }
    }
  }
}

.brand-related-products-block {
  .content {
    padding: 0 1rem;

    @media screen and (min-width: 910px) {
      margin: 0 -1rem;
    }
  }
}

.block-tekra-general-literature-block,
.block-tekra-data-sheets-block {
  .data-sheets-group-headline {
    font: 23px/26px $font-family--heading;
    margin-bottom: 1.5rem;
  }

  ul.accordion {
    margin-bottom: 3rem;
    padding-left: 0;

    .title,
    li.active .title {
      background-color: $tekra-blue;
    }
  }
}

.most-popular-block {
  h2 {
    font: bold 15px/22px $font-family--body;
  }

  .views-row {
    padding: 1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid $tekra-gray;
    }

    a {
      color: $tekra-blue;
      font: normal 15px/22px $font-family--body;
    }
  }

  .view-display-id-block_popular_newsletters .views-row a {
    font-size: 14px;
  }
}

.news-archives {
  h2 {
    font: bold 15px/22px $font-family--body;
  }

  ul {
    max-height: 250px;
    overflow-y: scroll;
    padding: 0 !important;

    &::-webkit-scrollbar {
      width: 8px;

      &-thumb {
        background: $tekra-blue;
        border-radius: 1rem;
      }
    }
  }
}

.sidebar-wrapper .block-menu-block {
  h2 {
    font: bold 15px/22px $font-family--body;
  }

  ul.sidebar-nav {
    padding: 0;
  }

  .leaf {
    padding: 0.5rem 0.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid $tekra-gray;
    }

    &:before {
      content: '\f148';
      display: inline-block;
      font-family: $font-awesome;
      height: 2.2rem;
      margin-right: 1rem;
      transform: rotate(90deg);
      width: 1rem;
    }

    a {
      color: $tekra-blue;
      font: normal 15px/22px $font-family--body;
    }
  }
}

#block-bean-sidebar-newsletter-signup {
  h2 {
    font: bold 15px/22px $font-family--body;
  }

  .form-item label {
    @include visually-hidden;
  }
}

.sidebar-social-media-links {
  margin-top: 4rem;

  h2 {
    font: bold 15px/22px $font-family--body;
  }

  ul.social-icons {
    list-style: none;
    padding: 0 !important;

    li {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  .content a {
    color: $tekra-blue;
    font-size: 21px;
  }
}
