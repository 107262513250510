.prop-65 {
  &__heading {
    margin-bottom: 0.5em;

    &--target {
      font-size: 2em;
      font-weight: 600;
    }
  }

  &__icon {
    width: 40px;
    height: 35px;
    display: inline-block;
    background: url('../images/prop-65-icon.svg');
    -webkit-background-size: cover;
    background-size: cover;

    &--target {
      vertical-align: text-bottom;
    }

    &:hover {
      background: url('../images/prop-65-icon--hover.svg');
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
}
