// Skip to results links
.results-link {
  margin: 1rem 0;
}

.field-name-results-anchor {
  position: relative;
}

.results-anchor {
  position: absolute;
}
