/* TEKRA Styles
*************************************************************/

@import "settings";
@import "mixins";
@import "layout/sidebars";
@import "layout/content";
@import "components/banners";
@import "components/blocks";
@import "components/brand-pages";
@import "components/facets";
@import "components/featured-landing-pages";
@import "components/footer";
@import "components/homepage";
@import "components/homepage-featured-brands";
@import "homepage-slideshow";
@import "components/landing-pages";
@import "components/market-pages";
@import "components/navigation";
@import "components/product-category";
@import "components/product-pages";
@import "components/product-spotlight";
@import "components/prop-65";
@import "components/search-form";
@import "components/skip-to-results-link";
@import "components/teasers";
@import "components/utility-news-cart-search";
@import "views/brand-related-product-categories";
@import "views/listing-views";
@import "views/product-listing";
@import "views/resources";

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

body {
  font-family: $font-family--body;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family--heading;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 7px;

  &.centered {
    text-align: center;
  }
}

p {
  font-size: 14px;
}

a {
  font-size: inherit;
}

a:hover {
  color: $tekra-blue-hover;
}

ul.product-list {
  list-style: none;

  li {
    list-style: none;
  }

  p {
    margin: 0;
  }
}

ul.pager {
  list-style: none;

  li {
    list-style: none;
    float: left;
    margin-right: 10px;
  }
}

table {
  width: 100%;
}

/* Custom Classes
***************************************************************************/
.outline {
  outline: 1px solid red;
}

.bckgrd-grey-gradient {
  background: $tekra-gray url('../images/content-grey-gradient.jpg') left bottom repeat-x;
}

.no-bckgrd {
  background-color: transparent;
}

.blue-border-top {
  border-top: 10px solid $tekra-blue;
}

.margin-none {
  margin: 0;
}

.margin-one {
  margin-bottom: 1em;
}

.margin-one-half {
  margin-bottom: 0.5em;
}

.margin-two {
  margin-bottom: 2em;
}

.margin-three {
  margin-bottom: 3em;
}

.margin-top-one {
  margin-top: 1em;
}

.margin-top-two {
  margin-top: 2em;
}

.margin-left-one {
  margin-left: 1em;
}

.margin-right-one {
  margin-right: 1em;
}

.right-always {
  float: right;
}

.left-always {
  float: left;
}

.add-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-lr {
  padding-left: 0;
  padding-right: 0;
}

.centered-text {
  text-align: center;
}

.glow-grey {
  -moz-box-shadow: 0 0 13px #333;
  -webkit-box-shadow: 0 0 13px #333;
  box-shadow: 0 0 13px #333;
}

.uppercase {
  text-transform: uppercase;
}

.bckgrd-fff {
  background-color: $white;
}

.tiny.button {
  background: $tekra-blue;
}

.tiny.button.micro {
  padding-left: 0;
  padding-right: 0;
  width: 70px;
}

input.required:focus {
  border: 1px solid red;
}

.hide {
  visibility: hidden;
}

.blue.title {
  color: $tekra-blue;
  font-weight: bold;
  font-size: 18px;
}

.button-operator {
  margin-left: 8px;
}

#custom-line-item-groups-checkout-form {
  float: right;
}

/* Foundation Button Overrides
***************************************************************************/
.button {
  background-color: $tekra-blue;
  border: 0;

  &:hover {
    background-color: $tekra-blue-hover;
  }

  &.blue-grad {
    background: #71BAEB url('../images/btn-blue-grad.jpg') left bottom repeat-x;

    &:hover {
      background: #2C72AF url('../images/bckgrd-btn-nav-over.png') left bottom repeat-x;
    }
  }

  &.green {
    background-color: $tekra-green;

    &:hover {
      background-color: $tekra-green-hover;
    }
  }

  &.arrow:after {
    display: inline-block;
    content: '\f0da';
    font-family: $font-awesome;
    margin-left: 5px;
  }
}

/* Foundation Breadcrumb Overrides
***************************************************************************/
ul.breadcrumbs {
  color: #333;
  background: transparent;
  border: none;
  margin-bottom: 10px;
  margin-left: 0;
  padding: 2rem 1rem !important;

  li {
    padding: 0 5px 0 0;
    text-transform: capitalize;

    a {
      color: $tekra-blue;
      padding-left: 5px;
      text-decoration: underline;
    }
  }

  a:hover {
    color: $tekra-blue-hover;
  }

  .current a {
    color: #333;
    text-decoration: none;
  }
}

/* Common Layout Elements
***************************************************************************/
/* Header
***************************************************************************/
.header {
  border-top: 12px solid $black;
  .header-top {
    @include constrain-content;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .logo {
    max-width: 180px;
    padding: 1rem;

    @media screen and (min-width: 500px) {
      max-width: 100%;
      padding: 2.5rem 1.5rem 1rem;
    }

    a,
    img {
      display: block;
      max-height: 84px;
    }
  }

  .utility-area {
    align-items: flex-end;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    justify-content: center;
    padding-right: 1rem;

    .phone-number {
      grid-area: phone;
      font-size: 1.8rem;
      display: none;
    }
    .block-dc-ajax-add-cart {
      grid-area: menu;
      display: none;
      justify-content: flex-end;
    }
    form {
      grid-area: search;
      margin-bottom: 0;
      display: none;
      justify-content: flex-end;

      > div {
        flex: 0 1 75%;
      }
    }
    .contact-button {
      grid-area: contact;
      display: none;
    }

    @media screen and (min-width: 500px) {
      display: grid;
      gap: 1rem;
      justify-content: stretch;
      grid-template-areas:
        "menu phone"
        "search contact";
      grid-template-columns: 2fr auto;

      .phone-number {
        grid-area: phone;
        font-size: 1.8rem;
        display: block;
      }
      .block-dc-ajax-add-cart {
        grid-area: menu;
        display: flex;
        justify-content: flex-end;
      }
      form {
        grid-area: search;
        margin-bottom: 0;
        display: flex;
        justify-content: flex-end;

        > div {
          flex: 0 1 75%;
        }
      }
      .contact-button {
        grid-area: contact;
        display: block;
      }
    }
  }

  .phone-contact {
    display: none;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-top: 1.8rem;

    @media screen and (min-width: 500px) {
      display: flex;
    }

    .phone-number {
      font-size: 1.8rem;
      line-height: 3.5rem;
    }

    .contact-button {
      margin-left: 2rem;
    }
  }

  .mobile-icon-menu {
    display: block;

    @media screen and (min-width: 500px) {
      display: none;
    }

    li {
      margin: 0 0.5rem;
    }

    a {
      //background: $black url('../images/bckgrd-btn-nav-up.png') left bottom repeat-x;
      background-color: $black;
      border-radius: 5px;
      color: $white;
      display: block;
      height: 32px;
      padding: 5px;
      text-align: center;
      width: 32px;

      &.blue {
        background-color: $tekra-blue;
      }
    }
  }
}

.frm-search-mobile {
  clear: right;
}

/* Navigation
***************************************************************************/
.fixed {
  z-index: 1000;
}

/* Foundation Orbit Slider Overrides
***************************************************************************/
.orbit-container {
  background: transparent;
}

.orbit-wrapper {
  div.timer {
    display: none;
    visibility: hidden;
  }

  .slider-nav span {
    background-repeat: no-repeat !important;
  }
}

.orbit-bullets {
  float: right;

  li {
    list-style-type: none;
    border: none;
  }
}

/* Home Page Orbit Slider
***************************************************************************/
.orbit.with-bullets {
  margin-bottom: 0;
}

.orbit-wrapper {
  .slider-nav {
    span {
      background: red;
      display: block;
      height: 38px;
      overflow: hidden;
      position: absolute;
      text-indent: 20px;
      top: 50%;
      width: 13px;
      z-index: 10;

      &.left {
        background: transparent url('../images/slider-btn-nav.png') left top no-repeat;
        left: 10px;
      }

      &.right {
        background: transparent url('../images/slider-btn-nav.png') right top no-repeat;
        right: 10px;
      }
    }
  }

  .orbit-bullets {
    background: transparent;
    left: inherit;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    right: 30px;
    top: -45px;
    z-index: 10;

    li {
      background: $white;
      border-radius: 20px;
      height: 10px;
      width: 11px;

      &.active {
        background: $tekra-blue-hover;
      }
    }
  }

  &::before {
    border-radius: 100px / 5px;
    bottom: 0;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.6);
    content: '';
    height: 10px;
    left: 2%;
    position: absolute;
    width: 96%;
    z-index: -1;
  }
}

.orbit-container {
  height: 250px;

  .orbit-slides-container > * {
    float: left;
    height: 100%;
    margin-left: 0;
    position: relative;
  }
}

#slider {
  height: 280px;
  list-style-type: none;
  overflow: hidden;

  .not-front & {
    height: 0;
    list-style-type: none;
    overflow: hidden;
  }

  &.orbit img {
    display: block;
  }
}

.orbit .orbit-slide {
  width: 100%;
}

#prodNews {
  .slider-nav span {
    background: #ff0000;
    display: block;
    height: 24px;
    overflow: hidden;
    position: absolute;
    text-indent: 30px;
    top: 40%;
    width: 24px;
    z-index: 900;
  }

  .my_prev_class,
  &.blue-border-top div.orbit-wrapper div.slider-nav span.left {
    background: transparent url('../images/prodNews-btn-nav.png') left top no-repeat;
    left: 0;
  }

  .my_next_class,
  &.blue-border-top div.orbit-wrapper div.slider-nav span.right {
    background: transparent url('../images/prodNews-btn-nav.png') right top no-repeat;
    right: 0;
  }

  &.blue-border-top div.orbit-wrapper ul.orbit-bullets {
    margin-left: 0 !important;
    padding-left: 35%;
    position: absolute;
    top: 190px;
  }

  .orbit-bullets {
    li {
      background: #D6D7D9;
      border-radius: 10px;

      &.active {
        background: #9B9B9B;
      }
    }
  }

  .orbit-wrapper {
    height: 0;
    max-height: 236px !important;
    overflow: hidden !important;
  }
}

#newsSlider {
  height: 236px;

  .orbit-slide {
    height: 0;
    max-height: 230px !important;
  }

  > img {
    height: 236px !important;
  }

  > div {
    display: none;
  }
}

.case-study {
  background: $white;
  border: 1px solid $tekra-gray;
  border-radius: 5px;
  box-shadow: 0 15px 15px -15px $tekra-gray-dark;
  margin: 2rem 2rem 3rem;
  padding: 2rem;

  @media screen and (min-width: 768px) {
    margin: 2rem 5rem 3rem;
  }

  h3 {
    color: $tekra-blue;
    background: transparent;
    border-bottom: 1px solid $tekra-blue;
    font: bold 18px/1.2em $font-family--heading;
    margin-top: 0;
    padding: 0 0 3px;
    text-transform: uppercase;
  }
}

#sideMenu h2 {
  margin-top: 0;
}

.page-intro {
  h1 {
    color: $tekra-blue;
    font-size: 28px;
    margin-top: 0;
  }
}

.list-item {
  padding-bottom: 1em;

  .list-image {
    background: $white;
    display: block;
    height: 156px;
    margin: 0 auto;
    overflow: hidden;
    width: 134px;
  }

  h2 {
    color: $tekra-blue;
    font-size: 24px;
  }
}

.main-content {
  padding-left: 20px;

  h2 {
    font-size: 16px;
    font-weight: bold;
  }
}

.row.list-image img {
  width: auto;
  height: 100%;
}

ul.pager {
  padding-bottom: 2em;
}

/* Faceted Search
***************************************************************************/
.sidr {
  .sidr-inner {
    .sidr-class-facetapi-limit-link {
      display: none !important;
    }

    ul, li {
      list-style: none !important;
    }

    li li ul {
      display: none;
    }

    > li a {
      border-bottom: 1px solid $black;
      font-size: 18px;
      line-height: 1.2em;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    > li.sidr-class-active > a {
      display: block;
      font-weight: bold;
      line-height: 1em;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 15px;
    }

    > li.has-dropdown .sidr-class-title > a {
      color: #2BA6CB;
      padding-left: 20px;
    }

    > li > a {
      display: block;
      font-weight: bold;
      line-height: 1em;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 15px;
    }

    h3.close {
      a {
        color: #ED9C28;
        text-transform: capitalize;
      }

      &:hover {
        cursor: pointer;
      }

      i {
        float: right;
        padding-top: 3px;
      }
    }

    a.sidr-class-active {
      color: #888;
    }
  }

  h3.sidr-class-block-title {
    font: bold 16px/1em $font-family--heading;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .sidr-class-facet-description {
    font-size: 11px;
    font-style: italic;
    margin-top: -20px;
    padding-left: 15px;
    padding-bottom: 10px;
  }

  ul {
    margin-bottom: 0;
  }
}

#responsive-sidebar-button {
  margin-bottom: 20px;
  text-align: left;
  width: auto !important;
}

fieldset.group-overview-tab {
  border: none;
  margin-bottom: 0;
  padding: 0;

  ul {
    margin-left: 2rem;
  }

  .commerce-product-field-field-features,
  .commerce-product-field-field-typical-applications {
    ul {
      margin-left: 4rem;
    }
  }

  &.field-group-htab {
    .fieldset-wrapper {
      h2 {
        font-size: 2rem;
        margin-top: 0;
      }
    }
  }
}

fieldset.group-downloads-tab {
  border: none;
  margin-top: 0;
  padding: 0;

  legend {
    background: $tekra-blue;
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 2rem;
    padding: 5px 10px;
    width: 100%;
  }

  .fieldset-wrapper {
    margin-left: 2rem;
  }
}

span.file {
  img.file-icon {
    vertical-align: middle;
  }
}

fieldset.group-products-tab.field-group-htab {
  border: none;
  padding: 0;

  .fieldset-wrapper {
    h3.block-title {
      background: $tekra-blue;
      display: block;
      color: $white;
      font: bold 16px/1.2em $font-family--heading;
      margin-bottom: 2rem;
      padding: 5px 10px;
      width: 100%;
    }

    > ul.product-list {
      > li {
        > p {
          border-bottom: 3px solid #333;
          font-size: 1.7rem;
          font-weight: bold;
          margin-bottom: 1.7rem;
        }

        ul.product-list.product-list-level-1 {
          > li {
            border-bottom: 1px solid #ccc;
            margin-bottom: 3rem;
            padding-bottom: 3rem;

            ul.product-list.product-list-level-2 {
              li {
                a {
                  display: inline-block;
                  padding-right: 1.2rem;
                  position: relative;

                  .icon-caret-right {
                    position: absolute;
                    right: 0;
                    top: 4px;
                  }
                }
              }
            }
          }

          li:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

#block-views-product-listing-block {
  padding: 0;
}

.commerce-product-field-field-custom-line-item-types {
  .custom-line-item-types-variations-form {
    a.tiny.button {
      font-size: 12px;
      padding: 10px 0;
    }
  }

  #custom_line_item_type_rfq_link {
    a.tiny.button,
    a.tiny.button.micro {
      @include green-gradient();
      border: 1px solid #447c23;

      &:hover {
        background: $tekra-green !important; /* Old browsers */
      }
    }
  }

  #custom_line_item_type_sample_link {
    a.tiny.button,
    a.tiny.button.micro {
      @include blue-gradient();
      border: 1px solid $tekra-blue;

      &:hover {
        background: $tekra-blue !important; /* Old browsers */
      }
    }
  }
}

#custom_line_item_type_rfq_link, #custom_line_item_type_sample_link {
  a.tiny.button {
    font-size: 11px;
    padding: 10px 0;
  }
}

.custom-line-item-types-links {
  #custom_line_item_type_rfq_link,
  #custom_line_item_type_sample_link {
    a.tiny.button.micro {
      font-size: 10px;
      margin-bottom: 0;
      padding: 10px 0;
      width: 100%;
    }
  }
}

.centered-text.custom-line-item-types-links {
  #custom_line_item_type_rfq_link,
  #custom_line_item_type_sample_link {
    display: block;
    width: auto;
  }
}

.cart-count {
  font-size: 11px;
}

/* Resources
***************************************************************************/
ul.accordion {
  border-bottom: 1px solid #E9E9E9;
  margin: 0 0 22px;

  .content {
    background: whitesmoke !important;

    li a {
      color: #111;

      &:hover {
        color: $tekra-blue-hover;
        text-decoration: underline;
      }
    }
  }

  > li {
    border-top: 1px solid #E9E9E9;
    list-style: none outside none;
    margin: 0;
    padding: 0;

    > div.title {
      font-size: 12px;
      color: $white;
      background: none repeat scroll 0 0 #F6F6F6;
      border-left: 1px solid #E9E9E9;
      border-right: 1px solid #E9E9E9;
      cursor: pointer;
      margin: 0;
      padding: 10px 15px;
      padding-left: 30px;
      position: relative;
      transition: background 0.15s linear 0s;

      h1, h2, h3, h4, h5, h6 {
        margin: 0;
      }

      &::before {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: transparent transparent transparent $white;
        border-image: none;
        border-style: solid;
        border-width: 6px;
        content: '';
        display: block;
        height: 0;
        left: 10px;
        position: absolute;
        top: 13px;
        width: 0;
      }

      &::after {
        border-style: none !important;
      }

      a {
        color: $white !important;
      }
    }

    &.active {
      border-top: 1px solid #E9E9E9;

      > div.title:before {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: $white transparent transparent transparent;
        border-image: none;
        border-style: solid;
        border-width: 6px;
        content: '';
        display: block;
        height: 0;
        left: 10px;
        padding-top: 10px;
        position: absolute;
        top: 18px;
        width: 0;
      }
    }
  }

  ul li {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

/* More Toggle Classes
***************************************************************************/
.copy-wrapper {
  height: auto;
  margin-bottom: 10px;
  overflow: visible;
}

.show-more {
  .arrow-up {
    background: transparent url('../images/arrows-up-down.png') left bottom no-repeat;
    display: block;
    float: right;
    line-height: 8px;
    margin-left: 5px;
    position: relative;
    top: 1px;
    width: 10px;
  }

  .arrow-down {
    background: transparent url('../images/arrows-up-down.png') right bottom no-repeat;
    display: block;
    float: right;
    line-height: 8px;
    margin-left: 5px;
    position: relative;
    top: 1px;
    width: 10px;
  }

  .arrow.active {
    background: transparent;
  }
}

.short-text {
  height: 3em;
  overflow: hidden;

  .node-type-brand & {
    height: 20em;
  }
}

.full-text {
  height: auto;
}

/* Footer
***************************************************************************/
.subfooter {
  background: $black;
  line-height: 4em;

  ul {
    margin-left: 0;
    margin-top: 1em;
  }

  li {
    margin-left: 0 !important;
  }

  p span {
    display: inline;
  }

  a {
    border-right: 1px solid $white;
    color: $white;
    font-size: 0.7em;
    padding: 0 5px;

    &:hover {
      text-decoration: underline;
    }

    &.last {
      border: 0;
    }
  }
}

.footer {
  clear: both;
  padding-bottom: 20px;

  h6 {
    font-weight: bold;
    margin-top: 0;
  }
}

/* CAPTCHA
***************************************************************************/
// 2023-09-12 - Scott Sawyer
// https://topfloortech.slack.com/archives/C027X7E3D/p1694532168741799
//.captcha.form-wrapper {
//  display: none;
//}

.captcha {
  margin-top: 20px;
}

/* FORMS
***************************************************************************/
.page-node-1268 .entity > h2,
.form-block .entity > h2 {
  display: none;
}

.entity > h2 {
  font-size: 22px;
}

#contact-us-entityform-edit-form {
  padding-top: 20px;
}

/**
 * 2022-07-18 - Scott Sawyer
 * Added .form-block to the style rule.
 */
#contact-us-entityform-edit-form,
#application-for-employment-entityform-edit-form,
.form-block {
  width: 90%;

  fieldset {
    border: 0;
    border-bottom: 1px solid #ddd;
    margin-top: 25px;
    padding-left: 0;
    padding-right: 0;
  }

  legend {
    background: transparent;
    display: block;
  }

  input {
    width: 90%;
  }

  select {
    margin-bottom: 12px;
    padding: 6px;
    width: 90%;
  }

  textarea {
    width: 90%;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: inline;
    margin-right: 5px;
    width: auto;
  }

  input[type='submit'] {
    margin-top: 20px;
    padding: 5px 10px;
    width: auto;
  }

  .form-type-checkbox label,
  .form-type-radio label {
    display: inline;
    width: auto;
  }

  div.error {
    background-position: right top;
    background-repeat: no-repeat;
  }
}

#application-for-employment-entityform-edit-form {
  .description {
    background: #ddd;
    color: #333;
    font-size: 12px;
    font-style: italic;
    margin-top: 5px;
    padding: 10px;
    width: 90%;
  }

  .form-wrapper {
    margin-bottom: 15px;
  }

  .field-widget-options-buttons {
    margin-bottom: 15px;
  }

  div.form-item.form-type-radio {
    padding-bottom: 5px;
  }

  .tabledrag-toggle-weight-wrapper {
    display: none;
  }

  #field-how-did-you-hear-add-more-wrapper {
    margin-top: 20px;
  }

  #field-positions-applied-for-values {
    margin-bottom: 10px;
  }

  #edit-field-positions-applied-for-und-add-more {
    margin-top: 0;
  }

  #field-employment-references-values {
    border-collapse: collapse;

    tr {
      border-bottom: 5px double #ddd;
    }

    .field-name-field-full-name {
      padding: 10px 0;
    }
  }
}

span.form-required {
  color: #ff0000;
}

/* Modals
***************************************************************************/
#custom-line-item-types-form {
  background: $white;
  padding: 0 1em 1em;

  #edit-submit-rfq {
    @include green-gradient();
    margin-right: 1em;

    &:hover {
      background-color: $tekra-green-light;
    }
  }

  #edit-submit-sample {
    @include blue-gradient();

    &:hover {
      background-color: $tekra-blue-light;
    }
  }
}

#custom-line-item-types-form,
.custom-line-item-types-variations-form-display {
  label {
    color: $tekra-blue;
    font: bold 18px $font-family--heading;
    padding: 10px 0;
  }

  select {
    padding: 6px;
    width: 90%;
  }

  .description {
    background: #ddd;
    color: #333;
    font-size: 12px;
    font-style: italic;
    margin: 10px 0;
    padding: 10px;
    width: 90%;
  }
}

.custom-line-item-message {
  opacity: 1.0;
}

#mobileSearch {
  .search-form {
    background: none;
    margin-top: 20px;
    width: 80%;
  }

  #search {
    border: 1px solid #ddd;
    font-size: 14px;
    padding: 6px;
    width: 100%;
  }

  #searchSubmit {
    position: relative;
    width: 20%;
  }
}


/* Media Queries
***************************************************************************/

/* Portrait Orientation */
@media screen and (orientation: portrait) {
  .right,
  .left {
    float: none !important;
  }

  .frm-search {
    clear: right;
  }

  .utility i {
    font-size: 2em !important;
  }
}

/* Small screens */
@media only screen and (max-width: 960px) {
  ul.block-grid.three-up.search-grid > li.item {
    clear: none;
    float: left;
    width: 50% !important;
  }
}

/* For Utility */
@media only screen and (max-width: 800px) {
  .utility {
    background: transparent;
    border: 0;
    margin-top: 10px;
  }

  .logo {
    margin: 20px 0 10px;
  }

  .top-bar.expanded {
    min-height: auto !important;
  }

  ul.dropdown.show-for-small {
    height: auto !important;
  }

  ul.block-grid.three-up.search-grid > li.item {
    clear: none;
    float: left;
    width: 50% !important;
  }
}

@media only screen and (max-width: 768px) {
  .top-bar ul > li.toggle-topbar-old {
    display: block;
  }
}

@media only screen and (max-width: 540px) {
  ul.block-grid.three-up.search-grid > li.item {
    clear: left;
    float: none;
    height: auto;
    width: 100% !important;
  }

  .search-grid .item .wrapper .content-wrapper {
    margin-bottom: 50px;
  }

  .search-grid .item .wrapper {
    min-height: 1px;
  }
}

#admin-menu a {
  font-size: 11px !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .sidr div.sidr-inner li a {
    display: block;
    font-size: 24px !important;
    font-weight: normal;
    line-height: 1em;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-top: 10px;
  }

  .sidr div.sidr-inner > li > a {
    display: block;
    font-size: 24px !important;
    font-weight: bold;
    line-height: 1em;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 769px) {
  .title-area.button-group {
    display: none;
  }
}

.media-youtube-video {
  position: relative;
}

.node-type-product .commerce-product-field {
  margin-top: 9px;
}

.view-toolbox-block-1 {
  .title {
    background: $tekra-blue;
    color: $white;
    font-size: 17px;
  }

  a.category {
    color: $black;
  }

  .views-row {
    border-bottom: 1px solid $black;
    margin: 0 3px;
    padding: 5px 0;
    text-align: center;

    &:last-child {
      border: none;
    }

    .field-content {
      padding: 4px 0;
    }
  }

  .relatedProduct_CTA {
    left: 30px;
    position: relative;

    i {
      color: $tekra-blue;
      margin-left: 5px;
    }
  }
}

.partners {
  .partner {
    background-color: $white;
    border: 1px solid $tekra-blue;
    margin-bottom: 30px;
    padding-top: 2px;
    text-align: center;

    a {
      display: block;
      margin-top: 2px;

      img {
        display: block;
      }

      span {
        color: $white;
        display: block;
        background-color: $tekra-blue;
        padding: 5px;

        &:hover {
          background-color: $tekra-blue-light;
        }
      }

      &:first-of-type {
        padding: 5px;
      }
    }
  }
}

.constrain {
  @include constrain-content;
}

.breadcrumbs-wrapper {
  flex: 1 0 100%;
}
