@import "settings";
@import "mixins";

.banner-homepage {
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 910px) {
    flex-direction: row;

    .group-background-image {
      order: 2;
    }

    .group-black-background {
      order: 1;
    }
  }

  .group-background-image,
  .group-black-background {
    flex: 0 0 50%;
  }

  .group-background-image {
    img {
      display: block;
      min-height: 350px;
      object-fit: cover;
      width: 100%;
    }
  }

  .group-black-background {
    background: $black url('../images/swoosh.png') no-repeat bottom left;
    flex-grow: 1;
  }

  .group-overlay {
    background: $black url('../images/swoosh.png') no-repeat bottom left;
    bottom: 0;
    left: 50%;
    max-width: 910px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;

    @media screen and (min-width: 910px) {
      background: none;
    }
  }

  .group-content {
    align-items: flex-start;
    color: $white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    padding: 2rem;
    width: 100%;

    @media screen and (min-width: 910px) {
      padding: 2rem 5rem 2rem 1rem;
      width: 50%;
    }
  }

  .field-name-field-headline .field-item {
    font: bold 22px/28px $font-family--heading;
    margin-bottom: 1rem;

    @media screen and (min-width: 600px) {
      font-size: 26px;
      line-height: 30px;
    }

    @media screen and (min-width: 910px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  .field-name-field-body .field-item {
    font: 13px/20px $font-family--body;
    margin-bottom: 2rem;

    @media screen and (min-width: 600px) {
      font-size: 14px;
      line-height: 21px;
    }

    @media screen and (min-width: 900px) {
      font: 15px/22px $font-family--body;
    }
  }

  .field-name-field-cta-link a {
    font-size: 13px;

    @media screen and (min-width: 900px) {
      font-size: 14px;
    }
  }
}

.view-display-id-block_homepage_slideshow {
  .views-row {
    display: none;

    &.views-row-1 {
      display: block;
    }
  }

  .slick-dots {
    @include slick-dots;
    bottom: 0;
    left: 50%;
    max-width: 910px;
    padding: 0 1rem !important;
    text-align: left;
    transform: translateX(-50%);
    width: 100%;

    li {
      margin-left: 0;
    }
  }
}
