body.front {
  .group-flex-wrapper {
    border-bottom: 3px solid $black;
    display: flex;
    flex-flow: row wrap;
    margin: 0 -1rem 4rem;
    padding-bottom: 13px;

    .group-content {
      flex: 1 1 410px;
      padding: 1rem;
    }

    .group-video {
      flex: 1 1 300px;
      padding: 1rem;
      text-align: center;
    }
  }

  h1 {
    font: 28px/32px $font-family--heading;
    color: $text-color;
    margin: 0 0 2rem;
  }

  .field-name-field-video-embed {
    position: relative;

    img {
      display: block;
    }

    &:after {
      color: $white;
      content: '\f04b';
      display: block;
      font: bold 3rem $font-awesome;
      left: 50%;
      pointer-events: none;
      position: absolute;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      top: 45%;
      transform: translate(-50%, -50%);
      transition: font-size 0.25s ease-in-out;
    }

    &:hover:after {
      font-size: 3.5rem;
    }
  }

  .video-embed-description {
    margin: 1rem;
    text-align: center;
  }
}
