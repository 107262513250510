.node-market.view-mode-full {
  h1 {
    font: bold 18px $font-family--heading;
  }

  .headline {
    font-size: 23px;
    line-height: 26px;
    margin-bottom: 2rem;
  }

  .block-title,
  h3 {
    background-color: $tekra-blue;
    clear: left;
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 2rem;
    padding: 5px 10px;
  }

  .case-study {
    h3 {
      background: transparent;
      border-bottom: 1px solid $tekra-blue;
      color: $tekra-blue;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2em;
      margin: 0 0 1rem;
      padding: 0 0 2px;
      text-transform: uppercase;
    }
  }

  .category-name {
    font-weight: bold;
  }

  .product-link {
    list-style: disc !important;
  }

  ul.product-list {
    padding-left: 10px !important;
  }
}
