.block-dc-ajax-add-cart {
  .ajax-shopping-cart-teaser {
    align-items: center;
    display: none;
    flex-flow: row nowrap;

    @media screen and (min-width: 500px) {
      display: flex;
    }

    div {
      font-size: 11px;
    }

    a {
      color: $tekra-blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .separator {
    margin: 0 0.75rem;
  }

  .cart-text {
    margin-right: 0.5rem;
  }

  .utility-search-block {
    position: relative;
    margin: 0 0 0 1rem;

    #views-exposed-form-site-search-block-search-results {
      margin: 0;
    }
  }
}
