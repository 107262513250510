.banner-banner-short {
  img {
    width: 100%;
  }
}

.entity-banner {
  &.banner-landing-page,
  &.banner-product-listing,
  &.banner-brand-page {
    background: $black url('../images/swoosh.png') no-repeat left center;
    background-size: cover;
    color: $white;

    .group-overlay {
      @include constrain-content;
    }

    .group-content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      max-width: 560px;
      padding: 3rem 1rem;
    }

    .headline {
      color: $white;
      font: bold 28px/32px $font-family--heading;
      margin-bottom: 1.5rem;
    }

    .field-name-field-body {
      font: 15px/22px $font-family--body;
      margin-bottom: 1.5rem;
    }

    .field-name-skip-to-results-link,
    .field-name-skip-to-products-link {
      a {
        font: bold 15px/33px $font-family--heading;

        &:hover {
          color: $white;
        }
      }
    }
  }
}
