.view-product-listing {
  position: relative;

  .view-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @media screen and (min-width: 920px) {
      margin-right: -1rem;
    }
  }

  .views-row {
    display: flex;
    flex: 1 1 100%;
    flex-flow: column nowrap;
    justify-content: stretch;
    min-height: 370px;
    padding: 0 1rem 1rem 0;
    position: relative;

    @media screen and (min-width: 600px) {
      flex: 0 0 50%;
    }

    @media screen and (min-width: 760px) {
      flex: 0 0 calc(100%/3);
    }
  }

  .node-teaser,
  .view-mode-product_grid_teaser{
    background-color: $tekra-gray;
    height: 100%;
    padding: 0 0 33px;
    position: relative;

    .content-wrapper {
      width: 100%;
    }
  }

  .field-name-title-serp-grid {
    display: block;
    background-color: $black;

    h2 {
      font: bold 15px/19px $font-family--heading;
      margin: 0 0 1rem;
      padding: 0.75rem;
      text-align: center;

      a {
        color: $white;
      }
    }
  }

  .field-name-image-serp-grid {
    margin-bottom: 2rem;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .commerce-product-field-field-features {
    padding: 0 2rem;
    font-family: $font-family--body;

    .label-above {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    ul {
      margin-left: 17px;
      padding: 0 !important;
    }

    li {
      font-size: 12px;
    }
  }

  .field-field-custom-line-item-types {
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    left: 0;
    position: absolute;
    right: 0;
  }

  .custom-line-item-types-variations-form,
  .custom-line-item-types-single {
    flex: 0 0 50%;

    a {
      font: bold 12px/33px $font-family--heading;
      padding: 0;
      width: 100%;
    }

    &#custom_line_item_type_rfq_link a {
      background-color: $tekra-green;

      &:hover {
        background-color: $tekra-green-hover;
      }
    }
  }

  .view-filters {
    position: absolute;
    top: -50px;
    right: 0;
  }

  .pager {
    align-items: center;
    border-top: 3px solid $black;
    display: flex;
    flex-flow: row nowrap;
    font-weight: bold;
    justify-content: center;
    line-height: 23px;
    margin-top: 2rem;
    padding: 2rem 0 2rem !important;

    a {
      color: $black;
    }

    .pager-first,
    .pager-previous {
      &:before {
        content: '\f100';
        font-family: $font-awesome;
        margin-right: 0.75rem;
      }
    }

    .pager-previous:before {
      content: '\f104';
    }

    .pager-next,
    .pager-last {
      &:after {
        content: '\f101';
        font-family: $font-awesome;
        margin-left: 0.75rem;
      }
    }

    .pager-next:after {
      content: '\f105';
    }

    .pager-item,
    .pager-current {
      background-color: $tekra-gray;
      text-align: center;
      width: 21px;
    }

    .pager-current {
      background-color: $tekra-blue-light;
      color: $white;
    }
  }

  .views-exposed-widgets {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;

    .views-exposed-widget {
      label {
        white-space: nowrap;

        &:after {
          content: '\f0d7';
          font-family: $font-awesome;
        }
      }


    }

    .views-widget,
    .views-widget-per-page {
      > .form-item {
        position: relative;

        .bef-select-as-links {
          height: 0;
          overflow: hidden;
          position: absolute;
          top: 100%;
          left: 0;

          > .form-item {
            background-color: $white;
            border: 2px solid $tekra-gray;
            white-space: nowrap;

            .form-item {
              padding: .5rem;

              &:not(:last-child) {
                border-bottom: 1px solid $tekra-gray;
              }

              a {
                color: $tekra-blue;
                font-weight: bold;
              }
            }
          }
        }

        &:hover {
          .bef-select-as-links {
            height: auto;
          }
        }
      }
    }

    .views-submit-button {
      @include visually-hidden;
    }
  }
}
