.footer-wrapper {
  background-color: $tekra-gray-medium;
  border-top: 3px solid $black;
}

.footer {
  @include constrain-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 2rem 0;
}

.subfooter-wrapper {
  background-color: $black;
}

.subfooter {
  @include constrain-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.universal-footer-wrapper {
  $mativ-color: #a3abb6;
  padding: 15px 5px;
  //position: relative;
  background-color: $mativ-color;

  /*
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $mativ-color;

    @media screen and (min-width: 768px) {
      border-top-right-radius: 1.5rem;
      max-width: calc(50vw + 465px);
    }
  }

   */

  .universal-footer {
    display: flex;
    //flex-direction: column-reverse;
    //justify-content: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 1rem;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;

      /*
      &::before {
        content: '';
        display: block;
        background-color: $mativ-color;
        position: absolute;
      }
      /**/

    }
  }

  .endorsement-emblem {
    //margin-bottom: 5px;
    margin-right: 24px;
  }

  .endorsement-text {
    //margin-bottom: 5px;
    margin-left: 24px;
    padding: 1rem 0;
    font-family: 'Space Grotesk - regular', sans-serif;
    font-size: 20pt;
    color: white;

    img {
      min-height: 20px;
    }
  }
}
