.sidebar-wrapper {
  flex: 1 0 205px;
  max-width: 100%;
  padding: 0 1rem;
}

.sidebar-title {
  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 10px;
  font-family: $font-family--heading;
}

.sidebar-nav {
  a {
    color: $black;
    font-size: 16px;
    font-family: $font-family--heading;

    &:hover {
      color: $tekra-blue-hover;
    }
  }

  li.active > a {
    color: $tekra-blue-hover;
  }
}
