.view-display-id-block_related_product_categories {
  border-top: 3px solid $black;
  padding: 3.5rem 0 6rem;

  .view-header {
    .product-category-grid-headline {
      font: normal 23px/26px $font-family--heading;
      margin-bottom: 2rem;
    }
  }

  .view-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: -1rem -0.5rem;
  }

  .views-row {
    clear: both;
    flex: 0 0 100%;
    overflow: hidden;
    padding: 0.5rem;
    position: relative;

    @media screen and (min-width: 600px) {
      flex-basis: 50%;
    }

    @media screen and (min-width: 910px) {
      flex-basis: calc(100%/3);
    }

    .node-product-category {
      background-color: $tekra-gray;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      padding: 1rem;
    }

    .group-image {
      flex: 0 0 100px;
    }

    img {
      display: block;
      height: auto;
      width: 100px;
    }

    .group-content {
      flex: 1 1 auto;
      padding-left: 1rem;
    }

    .teaser-title {
      font-size: 2rem;
      margin: 1rem 0 1.7rem;

      a {
        color: $tekra-blue;
      }
    }

    .field-name-node-link a {
      font-size: 1.2rem;
    }
  }
}
