.homepage-featured-brands {
  @include full-width-background;
  margin-bottom: 2rem;
  padding: 2.5rem 0;
  position: relative;

  .view-header {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 0 1rem;

    @media screen and (min-width: 400px) {
      flex-flow: row nowrap;
    }
  }

  .view-title {
    display: inline-block;
    font: 23px/26px $font-family--heading;
  }

  .view-partners-button {
    float: right;
    margin: 1rem 0;
  }

  .view-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 0;

    @media screen and (min-width: 640px) {
      flex-flow: row nowrap;
    }

    @media screen and (min-width: 960px) {
      margin: 0 -1rem;
    }
  }

  .views-row {
    flex: 1 1 200px;
    margin-bottom: 2rem;
    padding: 1rem 1rem 3rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid $tekra-gray-dark;

      @media screen and (min-width: 640px) {
        border-bottom: none;
      }
    }
  }

  .field-name-field-home-image img {
    width: 100%;
  }

  h3 {
    font: bold 15px/19px $font-family--heading;
    margin: 1.8rem 0 1.5rem;

    a {
      color: $text-color;
    }
  }

  .field-name-field-home-text {
    font: 15px/22px $font-family--body;
    margin-bottom: 1.7rem;
  }
}
