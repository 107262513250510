.facets-list-wrapper {
  @include constrain-content;
  display: flex;
  flex-flow: column nowrap;

  @media only screen and (min-width: 440px) {
    flex-flow: row nowrap;
  }

  .facets-wrapper {
    flex: 1 0 100%;
    order: 2;
    padding-right: 1.2rem;

    @media screen and (min-width: 440px) {
      flex: 0 0 220px;
      order: 1;
    }
  }

  .content-list {
    flex: 1 1 auto;
    order: 1;

    @media screen and (min-width: 440px) {
      order: 2;
    }
  }
}

.block-facetapi {
  border-bottom: 1px solid $tekra-gray;
  margin-bottom: 1rem;
  padding: 0.5rem;

  h2 {
    font: bold 14px/1.2rem $font-family--heading;
    margin-bottom: 1rem;

    &:before {
      color: $tekra-blue;
      content: '\f139';
      font-family: $font-awesome;
      margin-right: 0.75rem;
    }

    &.closed:before {
      content: '\f13a';
    }
  }

  ul {
    list-style-type: none;
    max-height: 110px;
    overflow-y: scroll;
    padding-left: 22px !important;

    &::-webkit-scrollbar {
      width: 8px;

      &-thumb {
        background: $tekra-blue;
        border-radius: 1rem;
      }
    }
  }
}

.item-list {
  .facetapi-facetapi-links li.leaf {
    list-style-image: none !important;
    list-style-type: none !important;

    a.facetapi-inactive {
      display: block;
      padding-left: 20px;
      font-size: 15px;
      color: $tekra-gray-dark;
      line-height: 22px;
      background: transparent url('../images/facet-checkbox.svg') left 3px/16px 16px no-repeat;
    }

    a.facetapi-active {
      display: block;
      padding-left: 20px;
      font-size: 15px;
      color: $tekra-gray-dark;
      line-height: 22px;
      background: transparent url('../images/facet-checkbox-active.svg') left 3px/16px 16px no-repeat;
    }
  }
}

.element-invisible,
.sidr-class-element-invisible {
  display: none !important;
}

a.sidr-class-facetapi-active {
  background: #111111;
  color: #AAAAAA !important;
}
