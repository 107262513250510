.node-product.view-mode-full {
  .field-name-field-images {
    float: none;
    margin-right: 1em;
    margin-bottom: 1em;

    @media (min-width: 600px) {
      float: left;
    }
  }

  h3.label-above {
    background-color: $tekra-blue;
    clear: left;
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2em;
    padding: 5px 10px;
  }

  .related-product-teasers {
    margin: 2rem 0;
    overflow: hidden;
    padding-bottom: 3rem;

    .node-product {
      border: 1px solid $tekra-gray;
      margin: 0 2rem;
      padding: 6rem 2rem 2rem;
      position: relative;
    }

    .product-category {
      background-color: $tekra-gray-medium;
      display: block;
      font: italic 12px $font-family--body;
      left: 0;
      padding: 1rem;
      position: absolute;
      right: 0;
      top: 0;

      a {
        color: $text-color;

        &:hover {
          color: $tekra-blue-light;
        }
      }
    }

    .teaser-image img {
      display: block;
      margin: 0 auto 2rem;
    }

    .teaser-title a {
      color: $black;
    }

    .slick-arrow {
      color: $tekra-blue;
      display: block;
      font-size: 2.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out;
      z-index: 10;

      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }

      &:hover {
        font-size: 3rem;

        &.prev {
          padding-right: 0.5rem;
        }

        &.next {
          padding-left: 0.5rem;
        }
      }
    }

    .slick-dots {
      @include slick-dots;
      bottom: 0;
      left: 0;
      margin: 0;
    }
  }
}
