@mixin blue-gradient() {
  background: $tekra-blue-light; /* Old browsers */
  background: -moz-linear-gradient(top, $tekra-blue-light 0%, $tekra-blue 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $tekra-blue-light), color-stop(100%, $tekra-blue)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $tekra-blue-light 0%, $tekra-blue 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $tekra-blue-light 0%, $tekra-blue 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $tekra-blue-light 0%, $tekra-blue 100%); /* IE10+ */
  background: linear-gradient(to bottom, $tekra-blue-light 0%, $tekra-blue 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$tekra-blue-light, endColorstr=$tekra-blue, GradientType=0); /* IE6-9 */
}

@mixin green-gradient() {
  background: $tekra-green-light; /* Old browsers */
  background: -moz-linear-gradient(top, $tekra-green-light 0%, $tekra-green 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $tekra-green-light), color-stop(100%, $tekra-green)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $tekra-green-light 0%, $tekra-green 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $tekra-green-light 0%, $tekra-green 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $tekra-green-light 0%, $tekra-green 100%); /* IE10+ */
  background: linear-gradient(to bottom, $tekra-green-light 0%, $tekra-green 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$tekra-green-light, endColorstr=$tekra-green, GradientType=0); /* IE6-9 */
}

@mixin constrain-content($max-width: 910px) {
  clear: both;
  margin: 0 auto;
  max-width: $max-width;
  position: relative;
}

@mixin visually-hidden() {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal;
}

@mixin full-width-background($color: $tekra-gray) {
  &:after {
    background-color: $color;
    bottom: 0;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }
}

@mixin slick-dots() {
  padding: 0 !important;

  li {
    height: 1rem;
    width: 1rem;

    button {
      height: 1rem;
      padding: 0;
      width: 1rem;

      &:before {
        color: $tekra-gray-medium;
        font-size: 1rem;
        height: 1rem;
        line-height: 1rem;
        opacity: 1;
        width: 1rem;
      }
    }

    &.slick-active button:before {
      color: $tekra-blue-light;
    }
  }
}

// Responsive video embeds.
@mixin responsive-video() {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}
