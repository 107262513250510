$tekra-blue: #0060B5 !default;
$tekra-blue-hover: #053F70 !default;
$tekra-blue-light: #178FD1 !default;
$tekra-green: #58A529 !default;
$tekra-green-light: lighten($tekra-green, 10%) !default;
$tekra-green-hover: darken($tekra-green, 10%) !default;
$tekra-gray: #EAEBEC !default;
$tekra-gray-medium: #BBBCBD !default;
$tekra-gray-dark: #222222 !default;
$black: #000000 !default;
$white: #FFFFFF !default;

$font-family--heading: 'PT Sans', Arial, sans-serif;
$font-family--body: Arial, sans-serif;
$font-awesome: 'Font Awesome 5 Pro';
$text-color: $tekra-gray-dark;

.page-node ul:not(.block-grid) {
  padding-left: 40px;
}

@font-face {
  font-family: 'Space Grotesk - light';
  src: url('../fonts/SpaceGrotesk-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Space Grotesk - regular';
  src: url('../fonts/SpaceGrotesk-Regular.ttf') format('truetype');
}


