.node-type-product-category {
  .view-mode-full h2 {
    font: 23px/26px $font-family--heading;
    margin-bottom: 2rem;
  }

  .field-name-field-body {
    margin-bottom: 3rem;
  }

  .listing-headline {
    @include constrain-content;
    border-top: 3px solid $black;
    font: 23px/26px $font-family--heading;
    padding: 2rem 1rem;

    @media screen and (min-width: 910px) {
      padding: 2rem 0;
    }
  }
}
