.node-type-featured-landing-page {
  .utility {
    display: none;
  }

  .interior-content {
    border-left: none;
  }

  .group-header {
    box-shadow: 0 15px 20px -15px #ccc;
    margin-bottom: 36px;
    padding: 0;

    img {
      display: block;
    }
  }

  .group-left,
  .group-right {
    margin-bottom: 32px;

    @media only screen and (max-width: 767px) {
      float: none;
      width: 100%;
    }
  }

  .field-name-title h1 {
    margin: 0 0 5px;
  }

  .field-name-body {
    ul {
      padding-left: 20px;
    }
  }

  .field-name-field-form {
    h2 {
      color: $tekra-blue;
      font-size: 28px;
      margin: 0 0 5px;
    }

    .addressfield-container-inline .form-item {
      margin: 0;
      width: 100%;
    }

    fieldset {
      border: none;
      margin: 0;
      padding: 0;

      legend {
        background: transparent;
        margin-bottom: 1rem;
      }

      select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: $white;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, rgb(253, 253, 253) 0%, rgb(240, 240, 240) 100%);
        background-position: right .7em top 50%, 0 0;
        background-repeat: no-repeat, repeat;
        background-size: .65em auto, 100%;
        border: 1px solid #ccc;
        border-radius: 0;
        box-sizing: border-box;
        color: #444;
        display: block;
        font-family: sans-serif;
        font-size: 14px;
        line-height: 1.2;
        margin: 0 0 12px;
        max-width: 100%;
        padding: .6em 1.4em .5em .8em;
        width: 100%;

        &::-ms-expand {
          display: none;
        }

        &:hover {
          border-color: #888;
        }

        &:focus {
          border-color: #aaa;
          box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
          box-shadow: 0 0 0 3px -moz-mac-focusring;
          color: #222;
          outline: none;
        }

        option {
          font-weight:normal;
        }
      }
    }
  }
}

// Featured Landing Page - Form Thank You Pages
.page-node-1879,
.page-node-1880 {
  .breadcrumbs {
    display: none;
  }

  #sidebar,
  .interior-content {
    margin-top: 1rem;
  }

  .interior-content .twelve.columns {
    .nine.columns {
      width: 100%;

      .embed-container {
        margin-bottom: 2rem;
      }
    }

    .three.columns {
      display: none;
    }
  }

  h1 {
    margin-left: 0;
  }
}
