div[about="/blog"] {
  h2 {
    @include visually-hidden;
  }
}

.resource-listings-view {
  .pager {
    align-items: center;
    border-top: 3px solid $black;
    display: flex;
    flex-flow: row nowrap;
    font-weight: bold;
    justify-content: center;
    line-height: 23px;
    margin-top: 2rem;
    padding: 2rem 0 2rem !important;

    a {
      color: $black;
    }

    .pager-first,
    .pager-previous {
      &:before {
        content: '\f100';
        font-family: $font-awesome;
        margin-right: 0.75rem;
      }
    }

    .pager-previous:before {
      content: '\f104';
    }

    .pager-next,
    .pager-last {
      &:after {
        content: '\f101';
        font-family: $font-awesome;
        margin-left: 0.75rem;
      }
    }

    .pager-next:after {
      content: '\f105';
    }

    .pager-item,
    .pager-current {
      background-color: $tekra-gray;
      text-align: center;
      width: 21px;
    }

    .pager-current {
      background-color: $tekra-blue-light;
      color: $white;
    }
  }

  .views-exposed-form {
    label {
      color: $text-color;
      font: bold 15px/19px $font-family--heading;
      margin-bottom: 1rem;
    }

    .form-submit {
      display: none;
    }

    .bef-select-as-links .form-item > .form-item {
      display: inline-block;
      margin: 0 0.5rem 0.5rem 0;

      a {
        background-color: $tekra-blue-hover;
        color: $white;
        display: block;
        font-weight: bold;
        padding: 1rem 2rem;

        &.active {
          background-color: lighten($tekra-blue-hover, 10%);
          text-decoration: underline;
        }

        &:hover {
          background-color: lighten($tekra-blue-hover, 10%);
        }
      }
    }
  }

  .view-mode-teaser {
    @media screen and (min-width: 600px) {
      align-items: stretch;
      flex-flow: row nowrap;
    }
  }
}

.node-resource-page,
.node-newsletter {
  &.node-teaser {
    margin-bottom: 1.3rem;

    .teaser-title {
      margin: 0 0 1rem;
    }

    .field-name-field-publish-date {
      font: italic 12px/22px $font-family--body;
      margin-bottom: .5rem;
    }
  }
}
